import "./App.css";
// import video from "./assets/bg-video.mp4";
import React from "react";
import discord from "./assets/discord.svg";
import twitter from "./assets/twitter.svg";
import instagram from "./assets/instagram.svg";

function App() {
  return (
    <div>
      <div className="backgroundcolor"></div>
      <div className="backgroundimage"></div>
      <div class="hero">
        <div class="header">
          <h2 className="text1">Henora</h2>
        </div>
        <div class="center">
          <div>
            <h1 className="text2">Soon</h1>
          </div>
        </div>
        <div class="bottom">
          <div className="tos">
            <p className="text3">TERMS & CONDITIONS PRIVACY POLICY</p>
          </div>
          <h1 className="text3 tos2">© 2022 HENORA</h1>
          <div className="socials">
            <a href="https://discord.gg/cPvbmzqwWA">
              <img src={discord} alt="" className="socialIcon" />
            </a>

            <a href="https://twitter.com/WeAreHenora">
              <img src={twitter} alt="" className="socialIcon" />
            </a>
            <a href="https://www.instagram.com/henora.io/">
              <img src={instagram} alt="" className="socialIcon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
